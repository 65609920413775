import './styles.css';

export const HeaderContent = (): JSX.Element => {
    return (
        <div className="header-container">
            {/* TODO: replace by an image*/}
            <h1>GiTrend</h1>
        </div>
    );
};
